<template>
  <div class="one-column-width">
    <v-snackbar v-model="snackbar" absolute top right color="success">
      <span>{{ snackBarText }}</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <v-snackbar v-model="errorMessage" absolute top right color="danger">
      <span>Something went RONG, Please Try Again!</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <!-- form start -->
    <!-- <v-card class="add-form-title" v-if="showFormData">
      <v-card-title
        >Application Summary<br />
        Student: {{ editObject.student_name }}, Applied Course: {{ editObject.course }}
      </v-card-title>

      <div class="col-md-11 m-5" style="border: 1px solid">
        <div class="col-md-12 row">
          <div class="col-md-3 pt-2 mt-5 form-group position-relative">
            <label>Student Email <span class="text-danger">*</span></label>
            <input :value="editObject.student_email" class="form-control pl-5" disabled />
          </div>
          <div
            class="col-md-3 pt-2 mt-5 form-group position-relative"
            v-for="(item, index) in applicationValues"
            :key="index"
          >
            <label>{{ item.label }} <span class="text-danger">*</span></label>
            <input :value="item.value" class="form-control pl-5" disabled />
          </div>
        </div>
      </div>

      <div class="mt-4 pt-2">
        <button
          type="button"
          class="btn btn-primary ml-2"
          @click="updateStatus('read', 1)"
          v-if="editObject.read == 0"
          :disabled="submitting"
        >
          <b-spinner small type="grow" v-if="submitting"></b-spinner>
          Mark As Received
        </button>
        <button
          type="button"
          class="btn btn-primary ml-2"
          @click="updateStatus('read', 0)"
          v-if="editObject.read == 1"
          disabled
        >
          Mark As Received
        </button>
        <button
          type="button"
          class="btn btn-primary ml-2"
          @click="updateStatus('status', 1)"
          v-if="editObject.status == 0"
          :disabled="submitting"
        >
          <b-spinner small type="grow" v-if="submitting"></b-spinner>
          Mark As Accepted
        </button>
        <button
          type="button"
          class="btn btn-primary ml-2"
          @click="updateStatus('status', 0)"
          v-if="editObject.status == 1"
          :disabled="submitting"
        >
          <b-spinner small type="grow" v-if="submitting"></b-spinner>
          Mark As Rejected
        </button>
        <button type="button" class="btn btn-soft-danger ml-2" @click="cancelForm">
          Close Summary
        </button>
      </div>
      <br />
    </v-card> -->
    <!-- form end -->

    <!-- table start -->
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search Student Applications"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="tableData" :search="search">
        <template v-slot:item="props">
          <tr>
            <td style="width: 15%">
              {{ props.item.date }}
            </td>
            <td style="width: 17%">
              {{ props.item.student_name }}
            </td>
            <td style="width: 17%">
              {{ props.item.course }}
            </td>
            <td style="width: 15%">
              {{ props.item.form }}
            </td>
            <td style="width: 10%">
              {{ props.item.read_string }}
            </td>

            <td style="width: 10%">
              {{ props.item.status_string }}
            </td>

            <!-- <td colspan="12" style="padding: 0; width: 10%">
              <v-btn
                class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
                fab
                small
                @click="showEditForm(props.item)"
              >
                <v-icon dark>mdi-eye</v-icon>
              </v-btn>
              &nbsp;
              <v-btn
                class="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
                fab
                small
                @click="askUserAboutDelete(props.item)"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </td> -->
          </tr>
          <tr>
            <td colspan="12" style="padding: 0" @click="showEditForm(props.item)">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    View Application Summary
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card class="add-form-title" v-if="showFormData">
                      <v-card-title
                        >Application Summary<br />
                        <b>Student:</b> {{ editObject.student_name }},
                        <b>Applied Course:</b> {{ editObject.course }}
                      </v-card-title>

                      <div class="col-md-11 m-5" style="border: 1px solid">
                        <div class="col-md-12 row">
                          <div class="col-md-3 pt-2 mt-5 form-group position-relative">
                            <label
                              >Student Email <span class="text-danger">*</span></label
                            >
                            <input
                              :value="editObject.student_email"
                              class="form-control pl-5"
                              disabled
                            />
                          </div>
                          <div
                            class="col-md-3 pt-2 mt-5 form-group position-relative"
                            v-for="(item, index) in applicationValues"
                            :key="index"
                          >
                            <label
                              >{{ item.label }} <span class="text-danger">*</span></label
                            >
                            <input
                              :value="item.value"
                              class="form-control pl-5"
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div class="mt-4 pt-2">
                        <button
                          type="button"
                          class="btn btn-primary ml-2"
                          @click="updateStatus('read', 1)"
                          v-if="editObject.read == 0"
                          :disabled="submitting"
                        >
                          <b-spinner small type="grow" v-if="submitting"></b-spinner>
                          Mark As Received
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary ml-2"
                          @click="updateStatus('read', 0)"
                          v-if="editObject.read == 1"
                          disabled
                        >
                          Mark As Received
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary ml-2"
                          @click="updateStatus('status', 1)"
                          v-if="editObject.status == 0"
                          :disabled="submitting"
                        >
                          <b-spinner small type="grow" v-if="submitting"></b-spinner>
                          Mark As Accepted
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary ml-2"
                          @click="updateStatus('status', 0)"
                          v-if="editObject.status == 1"
                          :disabled="submitting"
                        >
                          <b-spinner small type="grow" v-if="submitting"></b-spinner>
                          Mark As Rejected
                        </button>
                        <!-- <button
                          type="button"
                          class="btn btn-soft-danger ml-2"
                          @click="cancelForm"
                        >
                          Close Summary
                        </button> -->
                      </div>
                      <br />
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </td>
          </tr>
          <tr style="background: #eeeeee; width: 100%">
            <td colspan="12"><hr /></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <!-- table end -->
  </div>
</template>

<script>
import AdmissionForm from "@/MainServices/AdmissionForm.js";
import { required, minLength } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      editId: null,
      deleteId: null,
      dialog: false,
      snackbar: false,
      snackBarText: null,
      submitting: false,
      deleting: false,
      errorMessage: false,
      search: "",
      showFormData: false,
      applicationValues: null,
      headers: [
        {
          text: "Application Data",
          align: "left",
          sortable: false,
          value: "date",
          width: "15%",
        },
        {
          text: "Student Name",
          align: "left",
          sortable: false,
          value: "student_name",
          width: "17%",
        },
        { text: "Course", align: "left", sortable: false, value: "course", width: "17%" },
        {
          text: "Form",
          align: "left",
          sortable: false,
          value: "form",
          width: "15%",
        },
        {
          text: "Received",
          align: "left",
          sortable: false,
          value: "read_string",
          width: "10%",
        },
        {
          text: "status",
          align: "left",
          sortable: false,
          value: "status_string",
          width: "10%",
        },

        // { text: "Actions", value: "actions", sortable: false },
        // { text: "Summary", value: "summary", sortable: false },
      ],
      tableData: [],
    };
  },
  components: {},

  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    getInititalData() {
      AdmissionForm.getInstituteStudentApplications()
        .then((res) => {
          this.tableData = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // main methods
    showEditForm(item) {
      console.log(item);
      this.applicationValues = JSON.parse(item.values);
      this.editId = item.id;
      this.editObject = item;
      this.showFormData = true;
    },
    cancelForm() {
      this.showFormData = false;
      this.editObject = null;
      this.editId = null;
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },
    updateStatus(field, value) {
      let data = {
        id: this.editId,
        field: field,
        value: value,
      };
      this.submitting = true;
      AdmissionForm.updateInstituteStudentApplications(data)
        .then((res) => {
          this.getInititalData();
          this.submitting = false;
          this.snackBarText = "Fields Updated successful!";
          this.snackbar = true;
        })
        .catch((error) => {
          this.errorMessage = true;
        });
    },
    deleteItem() {
      this.deleting = true;
      AdmissionForm.editForm(this.form, this.editId)
        .then((res) => {
          this.resetForm();
          this.getInititalData();
          this.submitting = false;
          this.snackBarText = "Fields Updated successful!";
          this.snackbar = true;
        })
        .catch((error) => {
          this.errorMessage = true;
        });
    },
  },
  mounted() {
    this.getInititalData();
  },
};
</script>
